@font-face {
  font-family: "FT88-Regular";
  /* font-style: regular */
  src:  url("./fonts/FT88-Regular.woff2") format("woff2");
}

h1, h2, h3, h4, h5, h6{
  font-family: "FT88-regular";
  margin-top: 60px;
}
h1 {
  margin-top: 0px;
}

p, button {
  font-family: "FT88-regular";
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


h2 {
  padding-top: 50px;
}
h4 {
  margin-bottom: 0px;
}
button:hover{
  cursor: pointer;
}
.infoImage:hover{
  cursor: pointer;
}

#root{
  white-space: pre-line;
}